import React, { FunctionComponent, useEffect, useState } from "react";
import "./App.css";
import {
  Card,
  Upload,
  message,
  Image,
  Button,
  Spin,
  Checkbox,
  Typography,
  Select,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import moment from "moment";
import ExportButton from "./ExportButton";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { SelectValue } from "antd/lib/select";

const { Dragger } = Upload;
const { Option } = Select;

export interface AdsRow {
  name: string;
  date: string;
  movie: string;
}

const App: FunctionComponent = () => {
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [result, setResult] = useState<AdsRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onSumbit = () => {
    setLoading(true);
    setResult([]);
    files.map((file, fileIndex) => {
      let a = [];
      const reader = new FileReader();
      reader.onload = (textFile) => {
        if (typeof textFile.target.result === "string") {
          let rows = textFile.target.result.split("\n");
          if (rows.length == 1) {
            rows = textFile.target.result.split("\r\n");
          }
          const header = rows[0].split(",");
          let tempResult = [];
          rows.map((row, index) => {
            const cols = row.split(`",\"`);
            a[index] = cols;
            cols.map((col, colIndex) => {
              let colValue = col.replaceAll('"', "").replaceAll(`\"`,"").replaceAll(",",""); // FORMATO PARA NUMEROS
              if (
                Number(colValue) > 0 &&
                colIndex !== cols.length - 1 &&
                colIndex !== cols.length - 2
              ) {
                for (var i = 0; i < Number(colValue); i++) {
                  const fileName = file.name.replace(".csv", "");
                  const movieName = cols[0].replaceAll('"', "");
                  tempResult.push({
                    name: fileName.replace(".xls", ""),
                    date: a[0][colIndex],
                    movie: movieName,
                  });
                }
              }
            });
          });
          result.push(...tempResult);
          result.sort((a, b) => orderArray(a.date, b.date));
          setResult(
            result.map((res) => ({
              ...res,
              date: checkDate(res.date),
            }))
          );
        }
      };
      reader.readAsText(file.originFileObj);
      if (files.length - 1 === fileIndex) {
        setLoading(false);
      }
    });
  };
  const checkDate = (date: string) => {
    const monthlyFormat = "MMM YY";
    const dailyFormat = "MMM-DD";
    let resultDate = date.replaceAll('"', "").replaceAll("'", "");
    if (resultDate.length === 5) {
      resultDate = resultDate.slice(0, 4) + "0" + resultDate.slice(4);
    }
    if (moment(resultDate, monthlyFormat, true).isValid()) {
      return moment(resultDate, monthlyFormat).format(monthlyFormat).toString();
    }
    if (moment(resultDate, dailyFormat, true).isValid()) {
      return moment(resultDate, dailyFormat).format(`${dailyFormat}-YYYY`).toString();
    }
  };

  const orderArray = (firstDate: string, secondDate: string): number => {
    const monthlyFormat = "MMM YY";
    const dailyFormat = "MMM-DD";
    let resultDate = firstDate.replaceAll('"', "").replaceAll("'", "");
    let endDate = secondDate.replaceAll('"', "").replaceAll("'", "");
    if (resultDate.length === 5) {
      resultDate = resultDate.slice(0, 4) + "0" + resultDate.slice(4);
    }
    if (moment(resultDate, monthlyFormat, true).isValid()) {
      return moment(resultDate, monthlyFormat).unix() - moment(endDate, monthlyFormat).unix()
    }
    if (moment(resultDate, dailyFormat, true).isValid()) {
      return moment(resultDate, dailyFormat).unix() - moment(endDate, dailyFormat).unix()
    }
  };
  useEffect(() => {
    document.title = "AdSpeed Converter";
  }, []);

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          maxWidth: "100%",
          width: "400px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10%",
          }}
        >
          <Image
            width={300}
            height={"auto"}
            preview={false}
            src={"images/logo-adspeed.png"}
          />
        </div>
        <Dragger
          name={"file"}
          disabled={result.length !== 0}
          multiple={true}
          beforeUpload={() => {
            return false;
          }}
          onChange={(info) => {
            setFiles(info.fileList);
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            De click o arrastre los arrastre los archivos a esta área
          </p>
        </Dragger>
        {loading ? (
          <Spin />
        ) : (
          <div style={{ textAlign: "center", margin: "5%" }}>
            {files.length !== 0 && result.length === 0 && (
              <Button onClick={onSumbit} style={{ margin: "2%" }}>
                Procesar
              </Button>
            )}
            {result.length !== 0 && <ExportButton data={result} />}
          </div>
        )}
      </Card>
    </div>
  );
};

export default App;
